.header__resources-search--input {
  @media (max-width: 768px) {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 575px) {
  .filter_section {
    width: 100%;
  }
}
