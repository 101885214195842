.source-card:nth-of-type(odd) {
  background-color: #ffffff;
}
.source-card:nth-of-type(even) {
  background-color: #f9f9f9;
}
.source-card:last-of-type {
  border-bottom: 1px solid #e8e8e8;
}

.source-card {
  content-visibility: auto;
  contain-intrinsic-size: 150px;
  border-radius: 0;
  border-bottom: 0;
  @media (max-width: 575px) {
    contain-intrinsic-size: 280px;
  }
}
