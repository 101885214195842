.about {
  @media (min-width: 320px) and (max-width: 576px) {
    .gx-h1-lg {
      font-size: 22px;
    }
    ul.root-list {
      margin-left: 4px;
      padding-left: 16px;
      li {
        margin-left: 5px;
        ul {
          padding-left: 6px;
        }
      }
    }
  }

  p {
    text-align: justify;
    font-size: 1rem;
    line-height: 26px;
    text-justify: inter-character;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  span {
    font-size: 16px;
    line-height: 140%;
  }
  div {
    ul {
      list-style-type: '✔  ';
      margin-left: 16px;
      li {
        margin-bottom: 16px;
        margin-left: 10px;
        font-size: 1rem;
        ul {
          list-style-type: disc;
          margin-left: 16px;
          margin-top: 16px;
          margin-bottom: 16px;
          li {
            margin-bottom: 8px;
            span {
              line-height: 120%;
            }
          }
        }
      }
    }
  }
}

.access-assistance {
  p {
    text-align: justify;
    font-size: 1rem;
    line-height: 26px;
    text-justify: inter-character;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  span {
    font-size: 16px;
    line-height: 140%;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    div {
      img {
        margin: 4px !important;
      }
    }
    ul {
      padding-left: 4px;
    }
  }
  ul {
    list-style-type: disc;
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    li {
      margin-bottom: 8px;
      font-size: 16px;
      span {
        line-height: 120%;
      }
    }
  }
  div {
    img {
      margin: 10px;
    }
  }
}
#root,
body,
html {
  height: auto !important;
  overflow: inherit !important;
  scroll-behavior: smooth;
  scroll-padding-top: 140px;
  @media (min-width: 320px) and (max-width: 576px) {
    scroll-padding-top: 90px;
  }
}
