ul.ant-menu,
ul.ant-menu-vertical {
  border: none;
}

.gx-profile-banner {
  z-index: 0 !important;
}

.gx-avatar-name {
  text-transform: capitalize !important;
}

.gx-layout-sider-scrollbar-sidebar {
  height: calc(100vh - 72px) !important;
}
