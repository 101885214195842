.colon-hover {
  padding: 7px 5px !important;
  span {
    font-size: 14px !important;
  }
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #52c41a;
}
.ant-checkbox-inner {
  border: 1px solid #b5b5b5 !important;
}
.ant-form-item {
  margin-bottom: 0 !important;
}
.order-hover:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.active-order {
  background-color: #f9f9f9 !important;
}
.offers-table-columns {
  .ant-table-cell {
    padding: 8px 8px !important;
  }
}
.ant-table-filter-trigger-container {
  margin: auto 0 !important;
}

.ant-popover-inner-content {
  padding: 0px !important;
}
.read-more-less--more,
.read-more-less--less {
  color: #3da4e6;
  &:hover {
    text-decoration: underline;
  }
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0 !important;
}
.ant-menu ant-menu-sub ant-menu-inline {
  margin-left: -10px !important;
}

.ant-menu-sub {
  margin-left: -26px !important;
}
li.ant-menu-item-only-child {
  padding-right: 0 !important;
}
.ant-menu-submenu-arrow {
  right: 8px !important;
  left: auto !important;
}
.committeess {
  width: 310px !important;
  .ant-menu-title-content {
    div {
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
  .subtitle {
    margin-left: 10px;

    .ant-menu-title-content {
      padding-left: 15px !important;
      border-left: 1px solid rgba(171, 168, 149, 0.2);
    }
  }
}

.ant-menu-inline-collapsed {
  margin-left: 0 !important;
}
li.ant-pagination-total-text {
  order: -2 !important;
  position: absolute;
  left: 135px;
}
li.ant-pagination-options {
  order: -3 !important;
  margin-left: 0 !important;
  margin-right: 8px !important;
  position: absolute;
  left: 0;
}
.ant-popover-arrow {
  margin-top: -3px;
}
