.user-manuel {
  h1,
  h2,
  h3,
  h4 {
    text-transform: uppercase;
    font-weight: 500;
  }
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.65rem;
    color: @secondary-color;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.35rem;
    text-transform: none;
  }
  h5 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 16px;
  }
  p {
    font-size: 1rem;
    line-height: 26px;
    text-align: justify;
    text-justify: inter-character;
    margin-top: 8px;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  img {
    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.1));
    border-radius: 19px;
    padding: 8px;
    margin: 10px auto;
  }
  ul.list-table {
    list-style-type: none;
    padding-left: 0;
    li {
      min-height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #d1d1d1;
      p {
        margin-bottom: 8px;
      }
    }
    li:nth-of-type(even) {
      background-color: #fff;
      border-bottom: 0;
    }
    li:nth-of-type(odd) {
      background-color: #f5f5f5;
      border-bottom: 0;
    }
    li:last-child {
      border-bottom: 1px solid #d1d1d1;
    }
  }
}

.border-left-none {
  border-left: 0;
}
.border-top-none {
  border-top: 0;
}
.border-right-none {
  border-right: 0;
}
.border-bottom-none {
  border-bottom: 0;
}
.ant-collapse {
  border-radius: 0 !important;
  border: none !important;
}
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 !important;
}
.border-radius-none {
  border-radius: 0 !important;
}
.ant-collapse-item:last-of-type {
  border-bottom: 0 !important;
}

.background-color-white {
  background-color: #fff;
  .ant-collapse-content.ant-collapse-content-active {
    background-color: #f5f5f5;
  }
}
.ant-menu-submenu-arrow {
  display: none;
}
.ant-collapse-content-box {
  padding: 0 !important;

  ul {
    list-style-type: none;
    padding-left: 0 !important;
    li:last-of-type {
      border-bottom: 0;
    }
    li {
      height: 48px;
      border-top: 0;
      border-bottom: 1px solid #d1d1d1;
      display: flex;
      align-items: center;
      span::before {
        content: '🢒';
        margin-right: 8px;
      }
      span {
        margin-left: 40px;
        font-size: 16px;
        @media (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
  }
}

.left-side {
  position: sticky;
  top: 140px;
  overflow: hidden;

  .ant-card-body {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
}
.user-manuel {
  .ant-collapse-header {
    font-size: 16px !important;
    line-height: 150%;
  }
  .ant-card {
    margin-bottom: 0 !important;
  }
}
.user-manuel-card:nth-of-type(even) {
  background-color: #f9f9f9;
}
.user-manuel-card:nth-of-type(odd) {
  background-color: #ffffff;
}
.user-manuel-card:not(:last-child) {
  border-radius: 0;
  border-bottom: 1px solid #e8e8e8;
}
.text-color-isam {
  color: @secondary-color;
  font-weight: 500;
}
.collapse-button {
  display: none;

  @media (max-width: 575px) {
    width: 40px;
    height: 40px;
    left: 0;
    top: 50%;
    position: fixed;
    background: @primary-color;
    cursor: pointer;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    padding-top: 4px !important;
    color: #fff;
    opacity: 0.4;
    font-size: 26px;

    &:hover {
      opacity: 1;
    }
  }
}
li {
  p {
    margin-bottom: 8px;
  }
}
.position-md-relative {
  @media (min-width: 575px) {
    position: relative !important;
  }
}
