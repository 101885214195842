.jump-button {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  background: transparent;
  opacity: 0.2;
  transform: rotate(45deg);
  cursor: pointer;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid @primary-color;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 575px) {
    width: 30px;
    height: 30px;
    right: 30px;
    bottom: 30px;
    svg {
      font-size: 18px !important;
    }
  }
  svg {
    font-size: 26px;
    color: @primary-color;
  }

  &:hover {
    opacity: 0.7;
  }
}
